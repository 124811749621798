import { render, staticRenderFns } from "./UserMonthlyStatistics.vue?vue&type=template&id=47ed24d3&scoped=true"
import script from "./UserMonthlyStatistics.vue?vue&type=script&lang=js"
export * from "./UserMonthlyStatistics.vue?vue&type=script&lang=js"
import style0 from "./UserMonthlyStatistics.vue?vue&type=style&index=0&id=47ed24d3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ed24d3",
  null
  
)

export default component.exports