<template src="./Footer.html"></template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  display: flex;
  align-content: center;
  color: darkslategray;
  width: 100%;
  height: 80px; 
  border-top: solid 1px darkslategray;
}
</style>
