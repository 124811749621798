<template>
  <div class="container">
    <p>Acquisiti: <b style="color: green">{{ statistics.acquired }}</b></p>
    <p>Contestati: <b style="color: red">{{ statistics.contested }}</b></p>
    <p>Bonus: <b style="color: goldenrod">{{ statistics.bonus }}</b></p>
  </div>
</template>

<script>

export default {
  name: "UserMonthlyStatistics",
  props: ['statistics'],
};
</script>

<style scoped>
.container {
  border-radius: 30px;
  background: #e1edf9;
  height: 50px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
</style>
