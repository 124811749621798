<template src="./access_page.html"></template>
<script>
import Login from "@/components/login/login.vue";
import Registry from "@/components/registry/registry.vue";

export default {
  name: "accessPage",
  components: {
    Login,
    Registry,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.access-container {
  background: #e1edf9;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 0;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.delimiter {
  background: #107e93;
  border-radius: 40px;
  height: 2px;
  width: 50%;
  flex-shrink: 0;
}

@media (min-width: 992px) {
  .access-container {
    border-radius: 15px;
    max-width: 1500px;
    width: 60%;
    gap: 30px;
    margin: 50px 0;
    flex-direction: row;
  }
  .delimiter {
    height: 200px;
    width: 2px;
  }
}
</style>
