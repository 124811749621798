<template src="./comments.html"></template>
<script>
import { API_URL } from "../../main";
import axios from "axios";
import { Carousel, Slide } from "vue-carousel";
import StarRating from "@/components/star_rating/star_rating.vue";
import { utilities } from "@/Utility/miscellaneous.js";

export default {
  name: "Comments",
  computed: {
    utilities() {
      return utilities;
    },
  },
  components: {
    StarRating,
    Carousel,
    Slide,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      comments: [],
    };
  },
  mounted() {
    this.load_comments();
  },
  methods: {
    load_comments() {
      const url = API_URL + "users/LastCustomersCommentsList/";
      axios.get(url).then((response) => {
        this.comments = response.data;
      });
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  background: #cdcbcb;
  margin: 0;
  max-width: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.descriptive-text {
  margin-top: 30px;
}
h2 {
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
}
</style>
