<template src="./site.html"></template>

<script>
import { EventBus } from "../../main";

import Cart from '@/components/CartPage.vue'
import Comments from "../comments/comments.vue";
import Dashboard from "../dashboard/dashboard.vue";
import LeadsPage from "../leads_page/leads_page.vue";
import Login from "../login/login.vue";
import Registry from "../registry/registry.vue";
import SpecialOffers from "@/components/SpecialOffers.vue";
import SingleLead from "../single_lead/single_lead.vue";
import Totems from "../totems/totems.vue";
import AccessPage from "@/components/access_page/access_page.vue";

export default {
  name: "Site",
  components: {
    AccessPage,
    Cart,
    Comments,
    Dashboard,
    LeadsPage,
    Login,
    Registry,
    SingleLead,
    SpecialOffers,
    Totems,
  },
  data() {
    return {
      show_pages: {
        access_page: false,
        button_registry: false,
        cart: false,
        comments: false,
        dashboard: false,
        login: false,
        registry: false,
        single_lead: false,
        special_offers: false,
        totems: false,
      },
      lead: null,
      isAcquirable: null
    };
  },
  mounted() {
    const self = this;

    EventBus.$on("AccessPage", function (status) {
      self.show_pages.access_page = status;
    });

    EventBus.$on("Totems", function (status) {
      self.show_pages.totems = status;
    });

    EventBus.$on("Comments", function (status) {
      self.show_pages.comments = status;
    });

    EventBus.$on("SpecialOffers", function (status) {
      self.show_pages.special_offers = status;
    });

    EventBus.$on("ButtonRegistry", function (status) {
      self.show_pages.button_registry = status;
    });

    EventBus.$on("Registry", function (status) {
      self.show_pages.registry = status;
    });

    EventBus.$on("Dashboard", function (status) {
      self.show_pages.dashboard = status;
    });

    EventBus.$on("Leads", function (status) {
      self.show_pages.leads = status;
    });

    EventBus.$on("Login", function (status) {
      self.show_pages.login = status;
    });


    EventBus.$on("SingleLead", function (status) {
      self.show_pages.single_lead = status;
    });

    EventBus.$on("Cart", function (status) {
      self.show_pages.cart = status;
    });

    EventBus.$on("ShowLead", function (lead) {
      let orders = JSON.parse(sessionStorage.getItem('orders'));
      const result = orders.find(order => order.lead.id === lead.id);
      console.log(result);
      self.lead = (typeof result === 'undefined') ? lead : result.lead;
    });

  },
  methods: {},
};
</script>

<style scoped>
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container {
  width: 100%;
  flex-shrink: 0;
}
</style>
