<template src="./App.html"></template>

<script>
import { utilities } from "./Utility/miscellaneous.js";
import Navbar from "@/components/navbar/navbar.vue";
import Site from "@/components/site/site.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
    Navbar,
    Site,
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectedParam = urlParams.get('redirected');
    if(redirectedParam && !sessionStorage.getItem("user_id")){
    utilities.show_page("access_page");
    return;
    }
    utilities.show_page("landing");
  },
};
</script>

<style>
@import url(https://db.onlinewebfonts.com/c/2b6d84ae2684792084b4f6960404eee5?family=Kessel+205+W00+Bold);
#app {
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}
.logo {
  font-family: "Kessel 205 W00 Bold", Avenir;
  color: #f4e86c;
  font-size: 25px;
  font-weight: bold;
}
</style>

