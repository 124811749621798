import { EventBus } from "../main";

export const utilities = {
  formatDate(date) {
    date = date.slice(8, 10) + "-" + date.slice(5, 7) + "-" + date.slice(0, 4);

    return date;
  },
  pages: [
    "AccessPage",
    "ButtonRegistry",
    "Comments",
    "Dashboard",
    "Leads",
    "Cart",
    "Login",
    "Registry",
    "SpecialOffers",
    "SingleLead",
    "Totems",
  ],
  pages_groups: {
    access_page: ["AccessPage"],
    landing: ["Totems", "Comments", "SpecialOffers", "ButtonRegistry"],
    registration: ["Registry"],
    login: ["Login"],
    dashboard: ["Dashboard"],
    leads: ["Leads"],
    cart: ["Cart"],
    single_lead: ["SingleLead"],
  },
  hide_all() {
    this.pages.map(function (value, key) {
      EventBus.$emit(value, false);
    });
  },
  show_page(current_page) {
    this.hide_all();
    this.pages_groups[current_page].map(function (value, key) {
      EventBus.$emit(value, true);
    });
  },
  truncate(text, length) {
    if (text.length > length) {
      return text.substring(0, length) + "...";
    } else {
      return text;
    }
  },
  hideText(text) {
    if (text.length < 2) {
      return "***";
    }
    const firstChar = text[0];
    const lastChar = text[text.length - 1];
    const middleAsterisks = "*".repeat(6);
    return `${firstChar}${middleAsterisks}${lastChar.toUpperCase()}`;
  },
};
