<template src="./single_lead.html"></template>

<script>
export default {
  name: "SingleLead",
  props: ['lead'],
  methods: {
    addToCart() {
      if(!sessionStorage.getItem("cart")){
        sessionStorage.setItem("cart", JSON.stringify([this.lead]));
      } else{
        let cart = JSON.parse(sessionStorage.getItem("cart"));
        if(cart.some(lead => lead.id === this.lead.id)){
          return;
        }
        cart.push(this.lead);
        sessionStorage.setItem("cart", JSON.stringify(cart));
      }
    }
  }
}

</script>



<style scoped>
.container {
  width: 100%;
  max-width: unset;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
}

.banner {
  width: 100%;
  height: 300px;
  background: url("@/assets/imgs/architecture-1868667_1280.jpg") center;
  filter: grayscale(100%);
  opacity: 0.5;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-info {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.company-logo {
  border-radius: 50%;
  background: white;
  width: 80px;
  height: 80px;
}

.wrapper {
  background: lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin-top: 20px;
  max-width: 1360px;
  width: 60%;
  background: white;
  display: flex;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.lead-info {
  flex: 2;
  text-align: left;
}

.tags {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 60px;
}

.tag {
  background-color: rgb(172, 170, 170);
  color: white;
  border-radius: 30px;
  padding: 0 10px;
}

.lead-description {
  height: 200px;
}

.buy-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.price {
  font-weight: 1000;
  font-size: 30px;
}

@media (max-width: 1270px) {
  .content {
    flex-direction: column;
  }

  .tags {
    flex-direction: column;
    align-items: start;
  }

  .buy-option {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .wrapper {
    background-color: white;
  }
}

h3 {
  font-weight: bold;
  margin-bottom: 20px;
}

h5 {
  font-weight: 1000;
  margin-bottom: 30px;
}

.geo-icon {
  color: goldenrod;
  margin-right: 5px;
}
</style>