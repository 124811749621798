import Vue from "vue";
import App from "./App.vue";

import "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.css";
import VueSessionStorage from "vue-sessionstorage";

Vue.use(VueSessionStorage);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

//const BASE_URL = 'http://localhost:8000/'
//const BASE_URL = "http://devcustomerback.labinno.it/";
const BASE_URL = 'http://lgback.innolabs.it/';
export const API_URL = BASE_URL + "api/";
export const MEDIA_URL = BASE_URL + "media/";
export const FILES_URL = BASE_URL + "media";
export const VERTICAL_ICONS_URL = BASE_URL + "media/verticals/";
export const EventBus = new Vue();

Vue.filter("formatDate", function (date) {
  date = date.slice(8, 10) + "-" + date.slice(5, 7) + "-" + date.slice(0, 4);
  return date;
});
Vue.filter("formatImageUrl", function(url){
  if(!url){
    return '';
  }
  return VERTICAL_ICONS_URL + url.substring(url.lastIndexOf('/') + 1);
});


//export const sessionStorage = window.sessionStorage

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
