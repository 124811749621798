<template src="./registry.html"></template>
<script>
import { API_URL } from "../../main";
import { EventBus } from "../../main";
import axios from "axios";
import { utilities } from "../../Utility/miscellaneous.js";

export default {
  name: "Users",
  props: [],
  components: {},
  data() {
    return {
      form_data: {
        company: "",
        contact_person: "",
        email: "",
        address: "",
        vat_number: "",
        user: "",
      },
    };
  },
  mounted() {
    const self = this;
  },
  methods: {
    register_customer() {
      const self = this;
      if (this.form_data.email == "") {
        this.clear_form();
        utilities.show_page("landing");
      } else {
        const url = API_URL + "users/RegistryDetail/";
        this.form_data.user = this.form_data.email;
        axios.post(url, this.form_data).then((response) => {
          self.clear_form();
          utilities.show_page("landing");
        });
      }
    },
    clear_form() {
      this.form_data = {
        company: "",
        contact_person: "",
        email: "",
        address: "",
        vat_number: "",
        user: "",
      };
    },
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 30px;
}
h2 {
  font-weight: bold;
}

@media (min-width: 992px) {
  .text-wrapper {
    margin-top: 10px;
  }
}
</style>
