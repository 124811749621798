<template src="./star_rating.html"></template>

<script>
export default {
  name: "StarRating",
  props: {
    rating: {
      type: Number,
      required: true,
    },
  },
  computed: {
    stars() {
      const fullStars = Math.floor(this.rating);
      const halfStar = this.rating % 1 >= 0.5 ? "star-half" : "star-fill";
      const emptyStars = Math.floor(5 - this.rating);
      let starsArray = [];

      for (let i = 0; i < fullStars; i++) {
        starsArray.push("star-fill");
      }
      if (this.rating % 1 !== 0) {
        starsArray.push(halfStar);
      }
      for (let i = 0; i < emptyStars; i++) {
        starsArray.push("star");
      }

      return starsArray;
    },
  },
};
</script>

<style scoped>
div {
  color: goldenrod;
}
</style>
