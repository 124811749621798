<template src="./totems.html"></template>
<script>
import { API_URL } from "../../main";
import { MEDIA_URL } from "../../main";
import axios from "axios";
import LeadCard from '@/components/LeadCard';

export default {
  name: "Totems",
  components: {
    LeadCard
  },
  data() {
    return {
      leads: [],
      media_url: MEDIA_URL,
      timer: null,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getLastLeads();
    }, 30000); // updates every 30 seconds
  },
  created() {
    this.getLastLeads();
    clearInterval(this.timer);
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
  methods: {
    getLastLeads() {
      const self = this;
      const url = API_URL + "leads/LastLeadsList/";

      axios.get(url).then((response) => {
        self.leads = response.data;
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style scoped>
.container {
  width: 80%;
  max-width: 1300px;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}
h5 {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}
h4 {
  color: #4399f1;
}
.lead-card-totem:last-child{
  display: none;
}
</style>
