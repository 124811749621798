<template>
    <div class="container">
        <h2>Il tuo carrello</h2>
        <div class="empy-cart" v-if="cartIsEmpty">
            <h5>
                Il tuo carrello è vuoto!
            </h5>
        </div>
        <div class="cart-list" v-if="!cartIsEmpty">
            <div class="cart-item" v-for="cartItem in cart">
                <b-icon-x-circle-fill class="delete-icon" title="Rimuovi dal carrello"
                    @click="removeItem(cartItem)"></b-icon-x-circle-fill>
                <p class="cart-item-description">{{ cartItem.description }}</p>
                <p class="cart-item-price">&euro; {{ cartItem.price }}</p>
            </div>
            <b-button class="buy-button" size="lg" @click="toCheckout">Acquista</b-button>
        </div>
    </div>
</template>

<script>
import { API_URL } from '@/main';
import axios from 'axios';

export default {
    name: "CartPage",
    data() {
        return {
            cartIsEmpty: true,
            cart: []
        }
    },
    mounted() {
        const cart = sessionStorage.getItem("cart");
        if (!cart || JSON.parse(cart).length === 0) {
            this.cartIsEmpty = true;
            return;
        }
        this.cartIsEmpty = false;
        this.cart = JSON.parse(cart);
    },
    methods: {
        removeItem(cartItem) {
            const updatedCart = this.cart.filter(item => item.id !== cartItem.id);
            this.cart = updatedCart;
            if (this.cart.length === 0) {
                this.cartIsEmpty = true;
            }
            sessionStorage.setItem("cart", JSON.stringify(this.cart));
        },
        async toCheckout() {
            const url = API_URL + "orders/LeadsBuyed/";
            try {
                const ids = this.cart.map(cartItem => cartItem.id);
                const data = {
                    leads: ids
                }
                const response = await axios.post(url, data);
                window.location.assign(response.data.url);
            } catch (e) {
                console.error(e);
            }
        }
    }
}

</script>

<style scoped>
.container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4em;
}

.cart-list {
    padding: 5em 10em 5em 10em;
    border-radius: 20px;
    background-color: rgba(136, 181, 255, 0.55);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10em;
}

.cart-item {
    display: flex;
    width: 100%;
    align-items: center;
    /*gap: 18em;*/
    justify-content: space-between;
    border-bottom: 1px solid white;
}

.delete-icon {
    font-size: 2em;
    color: rgb(228, 20, 20);
    cursor: pointer;
}

.cart-item-price {
    font-weight: 800;
    font-size: 1.3em;
    display: inline;
    white-space: nowrap;
}

.cart-item-description {
    font-weight: 600;
    font-size: 1.1em;
}

.buy-button {
    background-color: rgb(4, 4, 63);
}

h2 {
    text-decoration: underline;
    margin-top: 2em;
    text-align: left;
    font-weight: bold;
    color: rgb(4, 4, 63);
}

p {
    color: rgb(4, 4, 63);
}


@media (max-width: 780px) {
    .container{
        max-width: unset;
        padding: 0;
        margin: 0;
    }
    .cart-list{
        border-radius: 0;
        padding: 3em 1em 3em 1em;
        width: 100%;
    }
    h2{
        text-align: center;
    }

}
    
</style>