<template src="./login.html"></template>

<script>
import { EventBus } from "../../main";
import axios from "axios";
import { API_URL, BASE_PATH } from "../../main";
import { utilities } from "../../Utility/miscellaneous.js";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      show: true,
      showDismissibleAlert: false,
      showDismissibleAlert2: false,
    };
  },
  mounted() {
    const self = this;
    localStorage.token = ""; // To be used only to debug, to clear token */

    if (localStorage.token != "") {
      this.auth_by_token();
      this.show = false;
    } else {
      this.show_form();
    }

    EventBus.$on("RecoveredPassword", () => {
      self.show_form();
    });
  },
  methods: {
    show_form() {
      this.show = true;
      this.showDismissibleAlert = false;
      this.showDismissibleAlert2 = false;
    },
    auth_by_token(token) {
      const self = this;
      const url = API_URL + "users/LoginByToken/";
      axios
        .post(url, { token: localStorage.token })
        .then((response) => {
          self.showDismissibleAlert = false;
          sessionStorage.logged = true;
          sessionStorage.user_id = response.data.id;
          sessionStorage.player_id = response.data.user_id;
          localStorage.token = response.data.token;
          self.show = false;
          self.goto_dashboard();
        })
        .catch(function (error, code) {
          self.show = true;

          if (code == 404) {
            sessionStorage.logged = false;
            sessionStorage.user_id = null;
            self.showDismissibleAlert2 = true;
          }
        });
    },
    goto_dashboard() {
      EventBus.$emit("Dashboard", true);
    },
    async fakeLog(){
      sessionStorage.setItem('user_id', '1');
      await this.fetchUserOrders();
      EventBus.$emit("logged");
      utilities.show_page('dashboard');
    },
    async fetchUserOrders(){
      const url = API_URL + `leads/LastMonthCustomerLeads/${sessionStorage.getItem('user_id')}/`;
      try {
        const response = await axios.get(url);
        sessionStorage.setItem('orders', JSON.stringify(response.data))
      } catch (error) {
        console.error("Error fetching statistics data:", error);
      }
    },
    login_user() {
      if (this.form.username == "" || this.form.password == "") {
        utilities.show_page("landing");
        console.debug("1");
      } else {
        console.debug("2");
        const self = this;
        const url = API_URL + "users/Login/";
        axios
          .post(url, {
            username: this.form.username,
            password: this.form.password,
          })
          .then((response) => {
            self.showDismissibleAlert = false;
            sessionStorage.user_id = response.data.id;
            localStorage.token = response.data.token;
            EventBus.$emit("LogIn", true);
            self.show = false;
            self.goto_dashboard();
          })
          .catch(function (error, code) {
            if ((code = 404)) {
              self.showDismissibleAlert = true;
            }
          });
      }
    },
  },
};
</script>

<style scoped>
h4 {
  font-weight: bold;
}
</style>
