import { render, staticRenderFns } from "./single_lead.html?vue&type=template&id=0f18770e&scoped=true&external"
import script from "./single_lead.vue?vue&type=script&lang=js"
export * from "./single_lead.vue?vue&type=script&lang=js"
import style0 from "./single_lead.vue?vue&type=style&index=0&id=0f18770e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f18770e",
  null
  
)

export default component.exports