<template>
  <div class="lead-card" @click="toSingleLead(lead)">
    <div class="lead-card-title">
      <img :src="lead.vertical.image | formatImageUrl" alt="icon" class="vertical-logo">
      <h5>{{ lead.vertical.name }}</h5>
    </div>
    <div class="lead-info">
      <p style="height: 50px">
        <b-icon-chat-left-text class="mr-2" />
        {{ lead.description }}
      </p>
      <p class="mb-3">
        <b-icon-person-circle class="person-icon"></b-icon-person-circle>
        {{ `${lead.first_name} ${lead.last_name.includes('*') ? '*****' : lead.last_name}` }}
      </p>
      <p>
        <b-icon-geo-alt-fill class="geo-icon"></b-icon-geo-alt-fill>
        {{ `${lead.region.region}-${lead.province.province}` }}
      </p>
      <p style="font-weight: bold">
        <b-icon-coin class="cash-icon"></b-icon-coin>
        Prezzo: &euro; {{ lead.price }}
      </p>
    </div>
    <div v-if="specialOffer" class="special-offer">
      <strong> Offerta valida fino al: </strong>
      {{ specialOffer.end_date | formatDate }}
    </div>
    <p style="color: goldenrod; text-align: center;">Aggiunto il {{ lead.creation_date | formatDate }}</p>
  </div>
</template>

<script>
import { utilities } from '@/Utility/miscellaneous'
import { EventBus } from '@/main'
export default {
  name: "LeadCard",
  props: ['lead', 'specialOffer'],
  methods: {
    toSingleLead(lead) {
      if (!sessionStorage.user_id) {
        utilities.show_page('access_page')
      } else {
        utilities.show_page('single_lead')
        EventBus.$emit('ShowLead', lead, true)
      }
    }
  }
}
</script>

<style scoped>
h5 {
  font-weight: bold;
}

.lead-card {
  border: solid 1px #e1edf9;
  box-shadow: 3px 3px 14px rgba(136, 181, 255, 0.55);
  border-radius: 10px;
  width: 400px;
  cursor: pointer;
  text-align: left;
  transition: all ease-in-out 1s;
  font-size: 20px;
  font-weight: 400;
}

.lead-info {
  padding-left: 10px;
}

.lead-card:hover {
  box-shadow: 3px 3px 14px rgba(213, 234, 24, 0.65);
  border: solid 1px #d6ec09;
}

.lead-card-title {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 24px;
  padding: 10px 0 0 10px;
}

.vertical-logo {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: rgb(4, 4, 63);
}

.special-offer {
  background-color: #738A8F;
  color: #f6f3f4;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>