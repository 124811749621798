<template>
    <div class="container">
        <div class="descriptive-text">
            <h2>Approfitta subito delle nostre offerte speciali!</h2>
            <h4>Offerte disponibili per un tempo limitato</h4>
        </div>
        <div class="special-offers-card-container">
            <LeadCard v-for="offer in offers" :key="offer.id" :lead="offer.lead" :specialOffer="offer" />
        </div>
        <div class="register-invitation">
            <h3 style="margin-bottom: 30px;">Ogni giorno selezioniamo tra centinaia di lead in offerta speciale: registrati per rimanere aggiornato sulle
                nuove offerte speciali disponibili nelle categorie di tuo interesse</h3>
            <b-button v-if="!isLogged" style="background-color: #F4E86C; border: none;" @click="toLogin">Registrati Subito</b-button>
        </div>
    </div>
</div></template>

<script>
import axios from 'axios';
import { API_URL, EventBus } from '@/main';
import LeadCard from './LeadCard.vue';
import { utilities } from '@/Utility/miscellaneous';


export default {
    name: "SpecialOffers",
    components: {
        LeadCard
    },
    data() {
        return {
            isLogged: false,
            offers: []
        }
    },
    methods: {
        toLogin(){
            utilities.show_page('access_page');
        }
    },
    async mounted() {
        if(sessionStorage.getItem("user_id")){
            this.isLogged = true;
        }
        const url = API_URL + 'leads/SpecialOffersList/';
        try {
            this.offers = (await axios.get(url)).data.slice(0,6);
        } catch (e) {
            console.error(e);
        }
    }
}
</script>

<style scoped>
.container {
    margin-top: 100px;
    max-width: 1300px;
    margin-bottom: 100px;
}

.descriptive-text {
    margin-bottom: 80px;
}

.special-offers-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 60px;
}

h5 {
    font-weight: bold;
    font-size: 18px;
}

h4 {
    color: #4399f1;
}
</style>