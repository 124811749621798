<template src="./leads_page.html"></template>
<script>
import { API_URL, EventBus } from "@/main";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { utilities } from "@/Utility/miscellaneous";
import LeadCard from '@/components/LeadCard.vue'

export default {
  name: "LeadsPage",
  components: { Multiselect, LeadCard },
  async created() {
    await this.fetchProvinces();
    await this.fetchVerticals();
    await this.getTotalNumber();
    await this.fetchLeads();
  },
  methods: {
    async fetchProvinces() {
      const url = API_URL + "geographic_areas/ProvincesList/";
      try {
        const response = await axios.get(url);
        let regionsWithProvinces = {};

        response.data.forEach(provinceObj => {

          let regionId = provinceObj.region.id;
          let regionName = provinceObj.region.region;
          let provinceName = provinceObj.province;
          let provinceId = provinceObj.id;

          if (!regionsWithProvinces[regionId]) {
            regionsWithProvinces[regionId] = {
              id: regionId,
              name: regionName,
              provinces: []
            };
          }

          regionsWithProvinces[regionId].provinces.push({ id: provinceId, name: provinceName });
        });

        this.regions = Object.values(regionsWithProvinces);

      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },
    async fetchVerticals() {
      const url = API_URL + "verticals/VerticalsList/";
      try {
        const response = await axios.get(url);
        this.verticals = response.data.map((item) => {
          return { name: item.name, id: item.id };
        });
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    },
    async sendFilterRequest() {
      this.currentPage = 1;
      await this.fetchFilteredData();
    },
    async fetchFilteredData() {
      const urlList = API_URL + "leads/FilteredLeads/";
      const urlEnd = API_URL + "leads/CountFilteredLeads/";
      const verticals = this.selectedVerticals.map((item) => {
        return item.id;
      });
      const regions = this.selectedRegions.map((item) => {
        return item.id;
      });
      const provinces = this.selectedProvinces.map((item) => {
        return item.id;
      });
      const data = {
        page: this.currentPage,
        rows: this.perPage,
      };
      const rowData = {};
      if (verticals.length !== 0) {
        data.verticals = verticals;
        rowData.verticals = verticals;
      }
      if (regions.length !== 0) {
        data.regions = regions;
        rowData.regions = regions;
      }
      if (provinces.length !== 0) {
        data.provinces = provinces;
        rowData.provinces = provinces;
      }

      try {
        const response = await axios.post(urlList, data);
        const totalRows = await axios.post(urlEnd, rowData);
        this.leads = response.data;
        this.totalRows = totalRows.data;
      } catch (error) {
        console.error("Error filtering data :", error);
      }
    },
    async getTotalNumber() {
      const url = API_URL + "leads/CountTotalLeads/";
      try {
        const response = await axios.get(url);
        this.totalRows = response.data;
      } catch (error) {
        console.error("Error fetching leads :", error);
      }
    },
    async fetchLeads() {
      const url =
        API_URL +
        `leads/PaginatedLeadsList/${this.currentPage}/${this.perPage}/`;
      try {
        const response = await axios.get(url);
        this.leads = response.data;
      } catch (error) {
        console.error("Error fetching leads :", error);
      }
    },
    updateProvinces() {
      this.provinces = []
      this.selectedRegions.forEach(item => {
        item.provinces.forEach(province => {
          this.provinces.push(province)
        })
      });
    },
    
  },
  data() {
    return {
      regions: [],
      provinces: [],
      verticals: [],
      selectedVerticals: [],
      selectedProvinces: [],
      selectedRegions: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      perPageOptions: [10, 20, 30, 40],
      leads: [],
    };
  },
  computed: {
    paginatedLeads() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.leads.slice(start, end);
    },
    utilities() {
      return utilities;
    },
  },
  watch: {
    currentPage() {
      this.fetchFilteredData();
    },
    perPage() {
      this.currentPage = 1;
      this.fetchFilteredData();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1360px;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.lead-card {
  border: solid 1px #e1edf9;
  box-shadow: 3px 3px 14px rgba(136, 181, 255, 0.55);
  border-radius: 10px;
  padding: 10px;
  width: 380px;
  cursor: pointer;
  text-align: left;
  transition: all ease-in-out 1s;
}
.lead-card:hover{
  box-shadow: 3px 3px 14px rgba(213, 234, 24, 0.65);
  border: solid 1px #d6ec09;
}

.pages {
  margin-top: 40px;
}

.multiselect {
  width: 270px;
}

.amount-selector {
  width: 295px;
}

@media screen and (max-width: 940px) {
  .filters {
    flex-direction: column;
  }
}
</style>
