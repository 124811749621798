<template src="./navbar.html"></template>

<script>
import { EventBus } from "../../main";
import { utilities } from "../../Utility/miscellaneous.js";

export default {
  name: "Navbar",
  data() {
    return {
      logged: false,
      login: "login",
      logout: "logout",
    };
  },
  mounted() {
    const self = this;
    if(sessionStorage.getItem('user_id')){
      self.logged = true;
    }
    EventBus.$on("logged", () => {
      self.logged = true;
    });

    EventBus.$on("LogIn", (status) => {
      self.logged = status;
    });
  },
  methods: {
    auth(what) {
      const self = this;

      if (what == "login") {
        sessionStorage.setItem("logged", true);
        sessionStorage.setItem("auth_token", "aaaa-bbb-ccc");
        utilities.show_page("access_page");
        //EventBus.$emit("LogIn", true)
      } else {
        sessionStorage.setItem("logged", false);
        sessionStorage.setItem("auth_token", null);
        sessionStorage.clear();
        utilities.show_page("landing");
        EventBus.$emit("LogIn", false);
      }
    },
    toDashboard() {
      if(!sessionStorage.getItem('user_id')){
        utilities.show_page('access_page');
      } else{
        utilities.show_page("dashboard");
      }
    },
    toLeads() {
      utilities.show_page("leads");
    },
    toCart(){
      if(!sessionStorage.getItem("user_id")){
        return;
      } else{
        utilities.show_page("cart");
      }
    },
    refresh() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.nav-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid darkslategray;
  position: sticky;
  top: 0;
  z-index: 999;
  background: white;
}
.alert-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  min-height: 40px;
  max-height: 60px;
  gap: 5px;
  margin: 0;
}
.navbar-item {
  display: flex;
  align-items: center;
  max-width: 1500px;
  justify-content: space-between;
}
.left,
.brand {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand {
  cursor: pointer;
}
p,
h2 {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .navbar-item {
    width: 70%;
  }
  #nav-collapse {
    display: none !important;
  }
}
</style>
