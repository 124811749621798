<script>
import axios from "axios";
import { API_URL } from "@/main";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "masterdata-info",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      form: {
        company: "",
        email: "",
        name: "",
        address: "",
        vat_number: "",
      },
      states: {
        company: null,
        name: null,
        address: null,
      },
      userData: null,
      positiveAlert: 0,
      negativeAlert: 0,
    };
  },
  validations() {
    return {
      form: {
        company: { required },
        name: { required },
        address: { required },
      },
    };
  },
  methods: {
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        for (const field in this.v$.form) {
          if (this.v$.form[field]?.$error) {
            this.states[field] = false;
          }
        }
      } else {
        this.states = {
          company: null,
          name: null,
          address: null,
        };
        const url = API_URL + `users/RegistryDetail/${sessionStorage.user_id}/`;
        const data = {
          address: this.form.address,
          company: this.form.company,
          contact_person: this.form.name,
          vat_number: this.form.vat_number,
        };
        try {
          const response = await axios.patch(url, data);
          this.showAlert("positiveAlert");
        } catch (error) {
          this.showAlert("negativeAlert");
        }
      }
    },
    async fetchUserData(userId) {
      const url = API_URL + `users/RegistryDetail/${userId}/`;
      try {
        const response = await axios.get(url);
        this.userData = response.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    fillMasterdataInfo() {
      this.form = {
        company: this.userData.company,
        email: this.userData.user.email,
        name: this.userData.contact_person,
        address: this.userData.address,
        vat_number: this.userData.vat_number,
      };
    },
    showAlert(alert) {
      this[alert] = 5;
    },
  },
  async mounted() {
    await this.fetchUserData(sessionStorage.user_id);
    this.fillMasterdataInfo();
  },
};
</script>

<template src="./masterdata-info.html"></template>

<style scoped>
.container {
  width: 100%;
  padding: 40px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1 {
  margin-bottom: 30px;
}
</style>
