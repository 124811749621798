<template>
  <BContainer class="container" :key="componentKey">
    <h2>RIEPILOGO ULTIMO MESE</h2>
    <b-modal size="lg" id="modal-1" title="Richiesta Contestazione" v-b-modal.modal-prevent-closing ok-title="Invia"
      cancel-title="Annulla" @ok="dispute">
      <b-form-group id="input-group-2" label-for="name" label="Nome:">
        <b-form-input id="name" class="input" v-model="form.name" placeholder="Inserire nome e cognome" type="text"
          :state="states.name">
        </b-form-input>
        <b-form-invalid-feedback>
          Campo obbligatorio
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="email" label="Email:">
        <b-form-input id="email" class="input" v-model="form.email" placeholder="Inserire l'indirizzo email"
          :state="states.email">
        </b-form-input>
        <b-form-invalid-feedback>
          Inserire un indirizzo email valido
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group id="input-group-3" label-for="reason" label="Ragione:">
        <b-form-textarea id="name" class="input" v-model="form.reason" no-resize rows="5"
          placeholder="Inserire la ragione per il reclamo" type="text" :state="states.reason">
        </b-form-textarea>
        <b-form-invalid-feedback>
          Campo obbligatorio
        </b-form-invalid-feedback>
      </b-form-group>


    </b-modal>
    <UserMonthlyStatistics :statistics="statistics" />
    <div class="my-leads-section">
      <h4 style="text-align: left" class="mb-3">Leads acquistati nell'ultimo mese:</h4>
      <div v-for="(group, vertical) in groupedByVertical" :key="vertical">
        <h2 style="text-align: left">{{ vertical }}</h2>
        <div class="wrapper my-4">
          <div v-for="(item, index) in group" :key="index" class="lead-card" @click="toSingleLead(item.lead)">
            <h4 style="height: 80px;" v-b-tooltip.hover :title="item.lead.title">
              {{ utilities.truncate(item.lead.title, 40) }}</h4>
            <p style="height: 100px;">{{ item.lead.description }}</p>
            <h5 class="mb-3" v-b-tooltip.hover :title="item.lead.company">
              {{ utilities.truncate(item.lead.company, 25) }}</h5>
            <p style="font-weight: bold">Prezzo: &euro; {{ item.lead.price }}</p>
            <b-button v-b-modal.modal-1 class="mb-3" variant="danger" v-if="isWithin24Hours(item.creation_date)"
              @click="sendCall(item.id)">
              Contesta
            </b-button>
            <p style="color: lightgray">Acquistato il {{ item.creation_date }}</p>
          </div>
        </div>
      </div>
    </div>
  </BContainer>
</template>

<script>
import { EventBus, API_URL } from "@/main";
import axios from "axios";
import UserMonthlyStatistics from "@/components/UserMonthlyStatistics.vue";
import { utilities } from "@/Utility/miscellaneous";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

export default {
  name: "UserLeads",
  setup: () => ({ v$: useVuelidate() }),
  components: {
    UserMonthlyStatistics
  },
  data() {
    return {
      orders: [],
      statistics: {
        acquired: 0,
        contested: 0,
        bonus: 0
      },
      form: {
        name: "",
        email: "",
        reason: "",
        id: "",
      },
      states: {
        name: null,
        email: null,
        reason: null,
        id: null,
      },
      intervalId: null,
      startDate: null,
      endDate: null,
      componentKey: 0,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
        reason: { required },
      },
    };
  },
  created() {
    this.intervalId = setInterval(this.updateComponent, 1000 * 60 * 60); // check every hour
    this.orders = JSON.parse(sessionStorage.getItem('orders'));
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  computed: {
    utilities() {
      return utilities;
    },
    groupedByVertical() {
      return this.orders.reduce((grouped, item) => {
        const key = item.vertical.name;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(item);
        return grouped;
      }, {});
    },
  },
  methods: {
    async fetchLeads(userId) {
    },
    async dispute(bvModalEvent) {
      bvModalEvent.preventDefault();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        for (const field in this.v$.form) {
          if (this.v$.form[field]?.$error) {
            this.states[field] = false;
          }
        }
      } else {
        this.states = {
          company: null,
          email: null,
          name: null,
          address: null,
        };
        alert(this.form.id, this.form.reason);
      }
    },
    sendCall(id) {
      this.form.id = id;
    },
    isWithin24Hours(creationDate) {
      const now = new Date();
      const creation = new Date(creationDate);
      const oneDay = 1000 * 60 * 60 * 24;
      return now - creation <= oneDay;
    },
    getStatistics() {
      this.statistics.acquired = this.orders.length;
      this.statistics.contested = this.orders.filter(
        (item) => "cnt" === item.status
      ).length;
      this.statistics.bonus = this.orders.filter(
        (item) => "bns" === item.status
      ).length;
    },
    toSingleLead(lead) {
      utilities.show_page('single_lead')
      EventBus.$emit('ShowLead', lead, false)
    },
    updateComponent() {
      this.componentKey += 1; // Change the key to force a re-render
    },
  },
  async mounted() {
    await this.fetchLeads(sessionStorage.user_id);
    this.getStatistics();
  },
};
</script>

<style scoped>
.container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.my-leads-section {
  margin-top: 50px;
  margin-left: 150px;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.lead-card {
  border: solid 1px #e1edf9;
  box-shadow: 3px 3px 14px rgba(136, 181, 255, 0.55);
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  overflow: auto;
}

h2 {
  font-weight: bold;
}
</style>
