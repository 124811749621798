import { render, staticRenderFns } from "./masterdata-info.html?vue&type=template&id=55c78960&scoped=true&external"
import script from "./masterdata-info.vue?vue&type=script&lang=js"
export * from "./masterdata-info.vue?vue&type=script&lang=js"
import style0 from "./masterdata-info.vue?vue&type=style&index=0&id=55c78960&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c78960",
  null
  
)

export default component.exports