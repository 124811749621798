import { render, staticRenderFns } from "./totems.html?vue&type=template&id=034ffa09&scoped=true&external"
import script from "./totems.vue?vue&type=script&lang=js"
export * from "./totems.vue?vue&type=script&lang=js"
import style0 from "./totems.vue?vue&type=style&index=0&id=034ffa09&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034ffa09",
  null
  
)

export default component.exports