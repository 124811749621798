<template src="./dashboard.html"></template>

<script>
import MasterdataInfo from "@/components/masterdata-info/masterdata-info.vue";
import UserLeads from "@/components/UserLeads.vue";

export default {
  name: "Dashboard",
  components: {MasterdataInfo, UserLeads},
  data() {
    return {
      selected: 'master-data'
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style scoped>
.container {
  display: flex;
  max-width: unset;
  gap: 20px;
}

.section-bar {
  padding-top: 40px;
  flex: 1;
  text-align: left;
  box-shadow: 10px 0px 10px -3px rgba(176,157,157,0.75);
}

.section-links {
  padding-top: 40px;
}

.section-link {
  height: 50px;
  width: 300px;
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
}

.section-link-name{
  margin-top: 8px;
}
.section-link-name:hover, .active {
  text-decoration: underline;
  color: darkblue;
}

.content {
  flex: 4;
  display: flex;
  flex-direction: column;
}

h1 {
  text-align: left;
  font-weight: bold;
}



.person-icon{
  color: goldenrod;
  font-size: 25px;
}

@media (max-width: 1100px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-bar {
    border: none;
    text-align: unset;
    box-shadow: none;
  }

  h1 {
    text-align: unset;
  }
}
</style>
